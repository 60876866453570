import * as React from "react";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
// import { ReactComponent as Gear } from "../../assets/svg/gear.svg";
import { ReactComponent as Logout } from "../../assets/svg/logout.svg";
import { ReactComponent as NotificationIcon } from "../../assets/svg/notification-header.svg";
import logo from "../../assets/images/logo.png";
import userImage from "../../assets/images/user-icon.png";
import SvgIcon from "@mui/material/SvgIcon";
import Grid from "@mui/material/Grid";
import useAuth from "../../context/useAuth";
import { useNotifDrawer } from "../../context/NotificationSettings/NotificationDrawerContext";
import { Link } from "react-router-dom";

export const Header = () => {
  const { signout } = useAuth();
  const { toggleNotifSettingDrawer } = useNotifDrawer();

  return (
    <React.Fragment>
      <Grid item xs={12} display={"flex"} justifyContent={"space-between"}>
        <Box
          sx={{
            width: "88px",
            height: "40px",
          }}
        >
          <a href="/">
            <img src={logo} alt="Perigee Insights" title="Perige Insights" />
          </a>
        </Box>
        <Box display={"flex"}>
          <Box display={"flex"} alignItems={"center"} justifyContent={"center"} sx={{ marginRight: 2 }}>
            <Link target="_blank" to="/special/drought-in-east-africa">
              <Typography variant="subtitle1" sx={{ fontWeight: 400, color: "rgb(54, 78, 190)" }}>
                Drought in East Africa
              </Typography>
            </Link>
          </Box>
          <Box
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            sx={{ cursor: "pointer" }}
            onClick={() => {
              const r = window.confirm("Are you sure?");
              if (r) {
                signout();
              } else {
                return;
              }
            }}
          >
            <SvgIcon component={Logout} inheritViewBox />
          </Box>
          <Box
            mx={2}
            display={"flex"}
            alignItems={"center"}
            justifyContent={"center"}
            onClick={toggleNotifSettingDrawer}
            sx={{ cursor: "pointer" }}
          >
            <SvgIcon component={NotificationIcon} inheritViewBox sx={{ color: "transparent" }} />
          </Box>
          <Box>
            <IconButton sx={{ padding: 0, cursor: "default" }}>
              <Avatar sx={{ background: "transparent" }}>
                <Box
                  component="img"
                  sx={{
                    width: "40px",
                    height: "40px",
                  }}
                  alt="user picture"
                  src={userImage}
                />
              </Avatar>
            </IconButton>
          </Box>
        </Box>
      </Grid>
    </React.Fragment>
  );
};
