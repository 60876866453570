import { FC, useReducer } from "react";
import { initialDroughtInEastAfricaState, droughtInEastAfricaReducer } from "./droughtInEastAfricaReducer";
import DroughtInEastAfricaContext from "./DroughtInEastAfricaContext";

import { EastAfricaPayloadType } from "../../constants/droughtInEastAfrica";

interface IDroughtInEastAfricaProvider {
  children?: React.ReactElement | React.ReactElement[];
}

const DroughtInEastAfricaProvider: FC<IDroughtInEastAfricaProvider> = ({ children }) => {
  const [state, dispatch] = useReducer(droughtInEastAfricaReducer, initialDroughtInEastAfricaState);

  const setDroughtInEastAfricaCountries = (selected_countries: EastAfricaPayloadType) => {
    dispatch({ type: "SET_DROUGHT_IN_EAST_AFRICA_COUNTRIES", payload: selected_countries });
  };

  const setStartDate = (start_date: Date | null) => {
    dispatch({ type: "SET_START_DATE", payload: start_date });
  };

  const setEndDate = (end_date: Date | null) => {
    dispatch({ type: "SET_END_DATE", payload: end_date });
  };

  const resetDroughtInEastAfricaFilter = () => {
    dispatch({ type: "RESET_DROUGHT_IN_EAST_AFRICA" });
  };

  const validateDroughtInEastAfricaFilter = () => {
    dispatch({ type: "VALIDATE_DROUGHT_IN_EAST_AFRICA" });
  };

  return (
    <DroughtInEastAfricaContext.Provider
      value={{
        ...state,
        setDroughtInEastAfricaCountries,
        setStartDate,
        setEndDate,
        resetDroughtInEastAfricaFilter,
        validateDroughtInEastAfricaFilter,
      }}
    >
      {children}
    </DroughtInEastAfricaContext.Provider>
  );
};

export default DroughtInEastAfricaProvider;
