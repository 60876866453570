import { Route, Routes, Navigate, Outlet } from "react-router-dom";
import loadable from "@loadable/component";
import { MainLayout } from "../../layout/mainLayout";
import useAuth from "../../context/useAuth";
import DroughtInEastAfricaProvider from "../../context/DroughtInEastAfrica/DroughtInEastAfricaProvider";

const Main = loadable(() => import("../../pages/main"), {
  resolveComponent: (components) => components.Main,
});
const EventPage = loadable(() => import("../../pages/event/Event"));
const Signin = loadable(() => import("../../pages/signin/signin"));
const Signup = loadable(() => import("../../pages/signup/signup"));
const SignupActivate = loadable(() => import("../../pages/signup/signupActivate"));
const ForgetPassword = loadable(() => import("../../pages/forgetPassword/forgetPassword"));
const ForgetPasswordActivate = loadable(() => import("../../pages/forgetPassword/forgetPasswordActivate"));
const DroughtInEastAfrica = loadable(() => import("../../pages/special/DroughtInEastAfrica"));

const MainRoutes = () => {
  const PrivateRoutes = () => {
    const { isAuthenticated } = useAuth();
    return isAuthenticated ? <Outlet /> : <Navigate to="/signin" />;
  };

  return (
    <Routes>
      <Route element={<PrivateRoutes />}>
        <Route element={<MainLayout />} path={"/"}>
          <Route path={"/"} element={<Main />} />
          <Route path={"/event/:id"} element={<EventPage />} />
          <Route
            path={"/special/drought-in-east-africa"}
            element={
              <DroughtInEastAfricaProvider>
                <DroughtInEastAfrica />
              </DroughtInEastAfricaProvider>
            }
          />
        </Route>
      </Route>

      <Route path={"/signin"} element={<Signin />} />
      <Route path={"/signup"} element={<Signup />} />
      <Route path={"/forget-password"} element={<ForgetPassword />} />
      <Route path={"/signup/activate/:userId/:activationToken"} element={<SignupActivate />} />
      <Route path={"/forgot-password/activate/:userId/:activationToken"} element={<ForgetPasswordActivate />} />
    </Routes>
  );
};

export default MainRoutes;
