import urlCat from "urlcat";
import { TwitterCountOnType } from "../apis/useTwitter";
import { TDroughtInEastAfricaFilter, EastAfricaType } from "./droughtInEastAfrica";
import convertDateToString from "../utils/convertDateToString";

function convertFilterParamsToUrlParams(filterParams: TDroughtInEastAfricaFilter) {
  let ccodes = filterParams.selected_countries.map((country: EastAfricaType) => country.value)
  return {
    start_date: convertDateToString(filterParams.start_date), 
    end_date: convertDateToString(filterParams.end_date),
    selected_countries: ccodes.join(",")
  }
}

const API_URLS = {
  auth: {
    signin: "/signin/",
    signup: "/signup/",
    signupActivate: (userId?: string, activationToken?: string) =>
      urlCat("/signup/activate/:userId/:activationToken/", { userId, activationToken }),
    forgetPassword: "/forgot-password/",
    forgetPasswordActivate: (userId?: string, activationToken?: string) =>
      urlCat("/forgot-password/activate/:userId/:activationToken/", { userId, activationToken }),
  },
  user: {
    profile: (user_id: string | null) => urlCat("/profile/:user_id", { user_id }),
    saveNotificationSettings: (user_id: string | null) =>
      urlCat("/profile/:user_id/notification-settings/save/", { user_id }),
    getNotificationSetttings: (user_id: string | null) =>
      user_id != null ? urlCat("/profile/:user_id/notification-settings/", { user_id }) : "/profile/null/notification-settings/",
  },
  getGoogleTrend: (id: string, code?: string) =>
    code
      ? urlCat("/interest_prediction/:id/gtrend-graph-newschema?region=:code", { id, code })
      : urlCat("/interest_prediction/:id/gtrend-graph-newschema", { id }),
  getEvents: (queryParams?: object) => urlCat("/events/", { ...queryParams }),
  getContinents: (queryParams?: object) => urlCat("/continents/", {}),
  getCountries: (queryParams?: object) => urlCat("/countries/", {}),
  getTypes: (queryParams?: object) => urlCat("/types/", {}),
  getEventSources: (id?: string | null) => urlCat("/event/sources/:id/", { id }),
  getSingleEvent: (id?: string | null) => urlCat("/events/:id/", { id }),
  getSummaryOfAnEvent: (id?: string | null) => urlCat("/event/:id/summary/", { id }),
  getEmergencyUpdates: (id?: string | null, page: number = 1) =>
    urlCat("/emergency-updates/event/:id/?page=:page", { id, page }),
  getAcapsRisks: (id?: string | null, page: number = 1) => urlCat('/event/:id/acaps-risks/?page=:page', { id, page }),
  getNewsByEvent: (id: string, timeframe: string, code?: string) =>
    code
      ? urlCat("news/rss/event-related/:id/diagram?timeframe=:timeframe&country_code=:code", { id, timeframe, code })
      : urlCat("news/rss/event-related/:id/diagram?timeframe=:timeframe", { id, timeframe }),
  getArticles: (id: string, page: number, code?: string) =>
    code
      ? urlCat("news/rss/event-related/:id?page=:page&country_code=:code", { id, code, page })
      : urlCat("news/rss/event-related/:id?page=:page", { id, page }),
  getTwitterByEventId: (id: string, timeframe: string, count_on: TwitterCountOnType, user_country?: string) =>
    user_country
      ? urlCat(
          "socialmedia/tweets/event-related/:id/diagram?timeframe=:timeframe&count_on=:count_on&user_country=:user_country",
          { id, timeframe, count_on, user_country },
        )
      : urlCat("socialmedia/tweets/event-related/:id/diagram?timeframe=:timeframe&count_on=:count_on", {
          id,
          timeframe,
          count_on,
        }),
  getMapCountryInfoEvents: (iso3: string, page: number = 1) => urlCat('/event/map-country-info/:iso3/events/?page=:page', { iso3, page }),
  getMapCountryInfoReports: (iso3: string, page: number = 1) => urlCat('/event/map-country-info/:iso3/reports/?page=:page', { iso3, page }),
  getAvgToneForDroughtInEastAfrica: (filterParams: TDroughtInEastAfricaFilter) => urlCat("/drought-in-east-africa/avgtone", "", convertFilterParamsToUrlParams(filterParams)),
  getMediaVolumeForDroughtInEastAfrica: (filterParams: TDroughtInEastAfricaFilter) => urlCat("/drought-in-east-africa/media-volume", "", convertFilterParamsToUrlParams(filterParams)),
  getDroughtInEastAfricaArticles: (page: number = 1) => urlCat('/drought-in-east-africa/articles', "", { page })
};

export default API_URLS;
