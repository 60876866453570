export const eastAfrica = [
    {value: "AO", label: "Angola", color: "#ff7c43"},
    {value: "BC", label: "Botswana", color: "#2f4b7c"},
    {value: "CG", label: "DR Congo", color: "#665191"},
    {value: "MI", label: "Malawi", color: "#F76F8E"},
    {value: "MZ", label: "Mozambique", color: "#a05195"},
    {value: "WA", label: "Namibia", color: "#d45087"},
    {value: "SF", label: "South Africa", color: "#81171B"},
    {value: "ZA", label: "Zambia", color: "#82ca9d"},
    {value: "ZI", label: "Zimbabwe", color: "#8884d8"},
] as const

export type EastAfricaType = (typeof eastAfrica)[number];

export type EastAfricaPayloadType = EastAfricaType[] | [];


export type TDroughtInEastAfricaFilter = {
    selected_countries: EastAfricaPayloadType,
    start_date: Date | null,
    end_date: Date | null,
}