import { createContext } from "react";
import { IDroughtInEastAfricaFilter } from "./droughtInEastAfricaReducer";
import { EastAfricaPayloadType } from "../../constants/droughtInEastAfrica";

export interface IDroughtInEastAfricaContext extends IDroughtInEastAfricaFilter {
  setDroughtInEastAfricaCountries: (disasters: EastAfricaPayloadType) => void;
  setStartDate: (start_date: Date | null) => void;
  setEndDate: (end_date: Date | null) => void;
  resetDroughtInEastAfricaFilter: () => void;
  validateDroughtInEastAfricaFilter: () => void;
}

const DroughtInEastAfricaContext = createContext<IDroughtInEastAfricaContext | undefined>(undefined);

export default DroughtInEastAfricaContext;
