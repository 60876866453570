import { EastAfricaPayloadType } from "../../constants/droughtInEastAfrica";
import { eastAfrica } from "../../constants/droughtInEastAfrica";

export interface IDroughtInEastAfricaFilter {
  selected_countries: EastAfricaPayloadType;
  start_date: Date | null;
  end_date: Date | null;
  errors: { [key: string]: string };
}

export const initialDroughtInEastAfricaState: IDroughtInEastAfricaFilter = {
  selected_countries: [...eastAfrica],
  start_date: new Date("2024-01-01"),
  end_date: new Date(),
  errors: {},
};

export type TDroughtInEastAfricaAction =
  | { type: "SET_DROUGHT_IN_EAST_AFRICA_COUNTRIES"; payload: EastAfricaPayloadType }
  | { type: "SET_START_DATE"; payload: Date | null }
  | { type: "SET_END_DATE"; payload: Date | null }
  | { type: "VALIDATE_DROUGHT_IN_EAST_AFRICA" }
  | { type: "RESET_DROUGHT_IN_EAST_AFRICA" };

const isValidDateRange = (start_date: Date, end_date: Date): boolean => {
  return start_date < end_date;
};
export const droughtInEastAfricaReducer = (
  state: IDroughtInEastAfricaFilter,
  action: TDroughtInEastAfricaAction,
): IDroughtInEastAfricaFilter => {
  switch (action.type) {
    case "SET_DROUGHT_IN_EAST_AFRICA_COUNTRIES":
      return { ...state, selected_countries: action.payload };
    case "SET_START_DATE":
      return { ...state, start_date: action.payload };
    case "SET_END_DATE":
      return { ...state, end_date: action.payload };
    case "VALIDATE_DROUGHT_IN_EAST_AFRICA":
      let errors: { [key: string]: string } = {};

      if (state.selected_countries.length < 1) errors.selected_countries = "Please select a country";
      if (state.start_date === null) {
        errors.start_date = "Please pick a date";
      }
      if (state.end_date === null) {
        errors.end_date = "Please pick a date";
      }
      if (state.start_date !== null && state.end_date !== null) {
        if (!isValidDateRange(state.start_date, state.end_date)) {
          errors.start_date = "Start Date must be before End Date";
          errors.end_date = "End Date must be after Start Date";
        }
      }

      return { ...state, errors };
    case "RESET_DROUGHT_IN_EAST_AFRICA":
      return { ...initialDroughtInEastAfricaState };
    default:
      return state;
  }
};
